export const ProductDetails = [
  {
    id: 1,
    name: "MS-1200",
    horse_power: "45",
    working_width: "48",
    overall_width: "53-5/8",
    overall_length: "92-1/4",
    weight: 524.7,
    deck_height: "7-1/4",
    blade_tip_speed: 3992.5,
    hitch: "Cat 1",
    cutting_height: "Lowest as 1 inch, adjustable",
    cutting_capacity: "1-1/2",
    safety_guards_front: "rubber guard",
    safety_guards_rear: "safety chain guard",
    deck_structure:
      "Steel plate welding with hot dip galvanizing or designated surface processing",
    blade_carrier: "Dishpan",
    gearbox: "540 RPM, PTO Driven Gearbox, Cast Iron Housing",
    safety_clutch: "Standard",
    tail_wheel: "Solid Rubber",
    pto_shaft: "Wide angle 1-3/8 inch 6 spline",
    blade_overlap: "N/A",
    colors: "Silver, While, Red, Custom",
  },
  {
    id: 2,
    name: "MS-1500",
    horse_power: "65",
    working_width: "60",
    overall_width: "64-1/4",
    overall_length: "102-7/8",
    weight: 683,
    deck_height: "10",
    blade_tip_speed: 4974,
    hitch: "Cat 1",
    cutting_height: "Lowest as 1 inch, adjustable",
    cutting_capacity: "1-1/2",
    safety_guards_front: "rubber guard",
    safety_guards_rear: "safety chain guard",
    deck_structure:
      "Steel plate welding with hot dip galvanizing or designated surface processing",
    blade_carrier: "Dishpan",
    gearbox: "540 RPM, PTO Driven Gearbox, Cast Iron Housing",
    safety_clutch: "Standard",
    tail_wheel: "Solid Rubber",
    pto_shaft: "Wide angle 1-3/8 inch 6 spline",
    blade_overlap: "N/A",
    colors: "Silver, While, Red, Custom",
  },
  {
    id: 3,
    name: "MS-1800",
    horse_power: "75",
    working_width: "72",
    overall_width: "76-1/2",
    overall_length: "115-7/8",
    weight: 878.5,
    deck_height: "11-7/16",
    blade_tip_speed: 4530,
    hitch: "Cat 1",
    cutting_height: "Lowest as 1 inch, adjustable",
    cutting_capacity: "1-1/2",
    safety_guards_front: "rubber guard",
    safety_guards_rear: "safety chain guard",
    deck_structure:
      "Steel plate welding with hot dip galvanizing or designated surface processing",
    blade_carrier: "Dishpan",
    gearbox: "540 RPM, PTO Driven Gearbox, Cast Iron Housing",
    safety_clutch: "Standard",
    tail_wheel: "Solid Rubber",
    pto_shaft: "Wide angle 1-3/8 inch 6 spline",
    blade_overlap: "N/A",
    colors: "Silver, While, Red, Custom",
  },
  {
    id: 4,
    name: "HS-1500",
    horse_power: "130",
    working_width: "60",
    overall_width: "67-7/8",
    overall_length: "114-3/4",
    weight: 1206,
    deck_height: "15-3/8",
    blade_tip_speed: 4974,
    hitch: "Cat 1 & 2",
    cutting_height: "Lowest as 1 inch, adjustable",
    cutting_capacity: "2-1/2",
    safety_guards_front: "safety chain guard",
    safety_guards_rear: "safety chain guard",
    deck_structure:
      "Steel plate welding with hot dip galvanizing or designated surface processing",
    blade_carrier: "Reinforced dishpan",
    gearbox: "540 RPM, PTO Driven Gearbox, Cast Iron Housing",
    safety_clutch: "4 plate heavy duty",
    tail_wheel: "Solid Rubber",
    pto_shaft: "1-3/4 inch Z20 spline",
    blade_overlap: "N/A",
    colors: "Silver, While, Red, Custom",
  },
  {
    id: 5,
    name: "HS-1800",
    horse_power: "130",
    working_width: "72",
    overall_width: "79-11/16",
    overall_length: "130-3/8",
    weight: 1346,
    deck_height: "15-9/16",
    blade_tip_speed: 4530,
    hitch: "Cat 1 & 2",
    cutting_height: "Lowest as 1 inch, adjustable",
    cutting_capacity: "2-1/2",
    safety_guards_front: "safety chain guard",
    safety_guards_rear: "safety chain guard",
    deck_structure:
      "Steel plate welding with hot dip galvanizing or designated surface processing",
    blade_carrier: "Reinforced dishpan",
    gearbox: "540 RPM, PTO Driven Gearbox, Cast Iron Housing",
    safety_clutch: "4 plate heavy duty",
    tail_wheel: "Solid Rubber",
    pto_shaft: "1-3/4 inch Z20 spline",
    blade_overlap: "N/A",
    colors: "Silver, While, Red, Custom",
  },
  {
    id: 6,
    name: "HS-2400",
    horse_power: "130",
    working_width: "96",
    overall_width: "104-1/4",
    overall_length: "106",
    weight: 1933,
    deck_height: "13",
    blade_tip_speed: 4644,
    hitch: "Cat 1 & 3",
    cutting_height: "Lowest as 1 inch, adjustable",
    cutting_capacity: "2-1/2",
    safety_guards_front: "safety chain guard",
    safety_guards_rear: "safety chain guard",
    deck_structure:
      "Steel plate welding with hot dip galvanizing or designated surface processing",
    blade_carrier: "Reinforced dishpan",
    gearbox: "540 RPM, PTO Driven Gearbox, Cast Iron Housing",
    safety_clutch: "4 plate heavy duty",
    tail_wheel: "Solid Rubber x 2",
    pto_shaft: "1-3/4 inch Z20 spline",
    blade_overlap: "3-1/2 inch",
    colors: "Silver, While, Red, Custom",
  },
  {
    id: 7,
    name: "HS-3000",
    horse_power: "130",
    working_width: "120",
    overall_width: "129-1/8",
    overall_length: "119-1/4",
    weight: 2734,
    deck_height: "14-1/2",
    blade_tip_speed: 5703,
    hitch: "Cat 1 & 3",
    cutting_height: "Lowest as 1 inch, adjustable",
    cutting_capacity: "2-1/2",
    safety_guards_front: "safety chain guard",
    safety_guards_rear: "safety chain guard",
    deck_structure:
      "Steel plate welding with hot dip galvanizing or designated surface processing",
    blade_carrier: "Reinforced dishpan",
    gearbox: "540 RPM, PTO Driven Gearbox, Cast Iron Housing",
    safety_clutch: "4 plate heavy duty",
    tail_wheel: "Solid Rubber x 2",
    pto_shaft: "1-3/4 inch Z20 spline",
    blade_overlap: "5-1/2 inch",
    colors: "Silver, While, Red, Custom",
  },
  {
    id: 8,
    name: "MSF-3660",
    horse_power: "100~160",
    working_width: "144",
    overall_width: "154",
    overall_length: "158-3/4",
    weight: 2899,
    deck_height: "10-13/16",
    blade_tip_speed: 4980,
    hitch: "Swivel Tongue",
    cutting_height: "Lowest as 1 inch, adjustable",
    cutting_capacity: "1-1/2",
    safety_guards_front: "safety chain guard",
    safety_guards_rear: "safety chain guard",
    deck_structure:
      "Steel plate welding with hot dip galvanizing or designated surface processing",
    blade_carrier: "Reinforced dishpan",
    gearbox: "540 RPM, PTO Driven Gearbox, Cast Iron Housing",
    safety_clutch: "2 plate on each gearbox",
    tail_wheel: "Solid Rubber x 4",
    pto_shaft: "Wide angle 1-3/8 inch 6 spline",
    blade_overlap: "6 inch",
    colors: "Silver, While, Red, Custom",
  },
  {
    id: 9,
    name: "MSF-4560",
    horse_power: "120~160",
    working_width: "180",
    overall_width: "190-1/8",
    overall_length: "197",
    weight: 4961.5,
    deck_height: "11-5/16",
    blade_tip_speed: 4520,
    hitch: "Self-leveling Swivel Clevis",
    cutting_height: "Lowest as 1 inch, adjustable",
    cutting_capacity: "2-3/8",
    safety_guards_front: "safety chain guard",
    safety_guards_rear: "safety chain guard",
    deck_structure:
      "Steel plate welding with hot dip galvanizing or designated surface processing",
    blade_carrier: "Reinforced dishpan",
    gearbox: "540 RPM, PTO Driven Gearbox, Cast Iron Housing",
    safety_clutch: "4 plate on each gearbox",
    tail_wheel: "Solid Rubber x 6",
    pto_shaft: "1-3/4 inch Z20 spline",
    blade_overlap: "6 inch",
    colors: "Silver, While, Red, Custom",
  },
];
