












import Vue from "vue";
import { ContactUs, Email } from "@/data/ContactUs";

export default Vue.extend({
  name: "ContactUs",
  components: {},
  data: () => ({
    contactUs: ContactUs,
    email: Email,
  }),
});
