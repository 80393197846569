
































import Vue from "vue";
import { ProductsGroupBySeries } from "@/data/ProductsGroupBySeries";
import { ProductDetailList } from "@/data/ProductDetailList";
import { ProductDetails } from "@/data/ProductDetails";

export default Vue.extend({
  name: "ProductDetails",
  components: {},
  data: () => ({
    series: ProductsGroupBySeries,
    detailList: ProductDetailList,
    details: ProductDetails,
  }),
  computed: {
    productName(): string {
      return this.getProductName(parseInt(this.$route.params.id));
    },
    productDetailEntry() {
      return this.details.filter((detail) => {
        return detail.id === parseInt(this.$route.params.id);
      });
    },
    imgSrcs(): string[] {
      var productName = this.productName;
      var extension = ".jpg";
      var left_img = productName + "_left_g" + extension;
      var right_img = productName + "_right_g" + extension;
      var cutter_img = productName + "_cutter_g" + extension;
      // Gotcha for require() with webpack:
      // Webpack cannot import the file if its name is completely given as a variable
      // This is because it doesn't know the path at compile time if the path is stored in a variable.
      // But Webpack can detect files to bundle when it is given a string interpolation in require()
      // Webpack knows the path "../assets/img" at compile time so it can include all the files inside the folder
      return [
        `https://huini-tech.sgp1.digitaloceanspaces.com/img/${left_img}`,
        `https://huini-tech.sgp1.digitaloceanspaces.com/img/${right_img}`,
        `https://huini-tech.sgp1.digitaloceanspaces.com/img/${cutter_img}`,
      ];
    },
  },
  methods: {
    getProductName(id: number): string {
      for (var entry of this.series) {
        for (var product of entry.products) {
          if (product.id === id) {
            return product.name;
          }
        }
      }
      return "Invalid Product ID";
    },
    getProductDetailEntry(id: number) {
      for (var entry of this.details) {
        if (entry.id === id) {
          return entry;
        }
      }
      return "";
    },
  },
});
