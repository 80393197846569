









































import Vue from "vue";
import { ProductsGroupBySeries } from "@/data/ProductsGroupBySeries";
import { ProductDetails } from "@/data/ProductDetails";

export default Vue.extend({
  name: "ProductsPage",
  components: {},
  data: () => ({
    series: ProductsGroupBySeries,
    products: ProductDetails,
    headers: [
      {
        text: "Product",
        align: "start",
        sortable: false,
        value: "name",
        divider: true,
        width: "100px",
      },
      { text: "Horse Power", value: "horse_power" },
      { text: "Working Width (inch)", value: "working_width" },
      { text: "Overall Width (inch)", value: "overall_width" },
      { text: "Overall Length (inch)", value: "overall_length" },
      { text: "Weight (lb)", value: "weight" },
      { text: "Cutting Capacity (inch diameter)", value: "cutting_capacity" },
      { text: "Deck Height (inch)", value: "deck_height" },
      { text: "Blade Tip Speed (m/min)", value: "blade_tip_speed" },
    ],
  }),
});
