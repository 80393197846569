export const ProductDetailList = [
  { text: "Horsepower", key: "horse_power" },
  { text: "Hitch", key: "hitch" },
  { text: "Working Width (inch)", key: "working_width" },
  { text: "Overall Width (inch)", key: "overall_width" },
  { text: "Overall Length (inch)", key: "overall_length" },
  { text: "Weight (lb)", key: "weight" },
  { text: "Cutting Height", key: "cutting_height" },
  { text: "Cutting Capacity (inch diameter)", key: "cutting_capacity" },
  { text: "Deck Height (inch)", key: "deck_height" },
  { text: "Safety Guards (front)", key: "safety_guards_front" },
  { text: "Safety Guards (rear)", key: "safety_guards_rear" },
  { text: "Dech Structure", key: "deck_structure" },
  { text: "Blade Tip Speed", key: "blade_tip_speed" },
  { text: "Blade Carrier", key: "blade_carrier" },
  { text: "Gearbox", key: "gearbox" },
  { text: "Safety Clutch", key: "safety_clutch" },
  { text: "Tailwheel", key: "tail_wheel" },
  { text: "PTO Shaft", key: "pto_shaft" },
  { text: "Blade Overlap", key: "blade_overlap" },
  { text: "Colors", key: "colors" },
];
