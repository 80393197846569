














import Vue from "vue";
import { WhoAreWe, OurAdvantages } from "@/data/AboutUs";

export default Vue.extend({
  name: "HomePage",
  components: {},
  data: () => ({
    whoAreWe: WhoAreWe,
    ourAdvantages: OurAdvantages,
  }),
});
