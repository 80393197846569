export const ProductsGroupBySeries = [
  {
    code: "MS",
    description: "MS Series -- light duty",
    products: [
      { id: 1, name: "MS-1200" },
      { id: 2, name: "MS-1500" },
      { id: 3, name: "MS-1800" },
    ],
  },
  {
    code: "HS",
    description: "HS Series -- heavy duty",
    products: [
      { id: 4, name: "HS-1500" },
      { id: 5, name: "HS-1800" },
      { id: 6, name: "HS-2400" },
      { id: 7, name: "HS-3000" },
    ],
  },
  {
    code: "MSF",
    description: "MSF Series -- foldable light duty",
    products: [
      { id: 8, name: "MSF-3660" },
      { id: 9, name: "MSF-4560" },
    ],
  },
];
