


































import { MenuList } from "@/data/MenuList";
export default {
  data: () => ({
    drawer: false,
    links: MenuList,
  }),
};
